import React, { useContext, useState, useEffect } from "react";
import { featureEnabled } from "feature-toggles!sofe";
import { SigningContext } from "../signing-context";
import { DragWrapper } from "./drag-field-wrapper.component";
import { SignerFieldToolbar } from "./signer-toolbar/signer-field-toolbar.component";
import NewDraggableSigningField from "./new-draggable-field.component";
import { userMatchesSigningLocation } from "../signing-modal.helper";
import { ResizableFieldDimensions } from "../constants";
import { getDefaultFieldWidth } from "src/signing-resource.helper";

export const SigningObjects = ({
  documentRef,
  allSigningLocations,
  signingLocations,
  // page,
  loggedInUserSignature,
  signingLocationsAreDraggable,
  context,
  openSignatureEntryModal,
  isClient,
  clientCollaborators,
  displayShield,
  // modalMode,
  deleteSigningField,
  showSignerDropdown,
  preAuthSigner,
  // onSignatureClick,
  isTemplateMode,
  getFilledSignature,
  shouldShowSelectSignerModal,
  setSigner,
  setSigningLocations,
  duplicateSigningField,
  updateSigningObjectFieldType,
  markFieldAsSigned,
  activeToolbarId,
  handleActiveToolbarOpen,
  onDropdownRef,
}) => {
  const {
    signersContext,
    hideSignerToolbar,
    setHideSignerToolbar,
    esignAlreadySentOldFields,
    esignAlreadySentNewFields,
    hasEsignImprovementsBeta,
  } = useContext(SigningContext);

  const [prevLength, setPrevLength] = useState(0);
  const addedFieldCount = signingLocations.length - prevLength;

  useEffect(() => {
    if (signingLocations.length > 0) {
      //when EDITING an existing eSign template, hide the toolbar since all fields added at once
      if (addedFieldCount > 1) {
        setHideSignerToolbar(true);
      } else if (addedFieldCount === 1) {
        // only set active toolbar if one field was added (not if applying a template)
        const newestField = signingLocations[signingLocations.length - 1];
        if (newestField) {
          hideSignerToolbar && setHideSignerToolbar(false);
          if (activeToolbarId !== newestField.id) {
            // Set active/open toolbar only for the last field added
            handleActiveToolbarOpen(newestField.id);
          }
        }
      }
    }
    setPrevLength(signingLocations.length);
  }, [signingLocations.length]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'activeToolbarId', 'addedFieldCount', 'handleActiveToolbarOpen', 'hideSignerToolbar', 'setHideSignerToolbar', and 'signingLocations'

  useEffect(() => {
    // reset hideSignerToolbar after applying a template
    if (prevLength > 0 && addedFieldCount === 0) {
      setHideSignerToolbar(false);
    }
  }, [prevLength, addedFieldCount, setHideSignerToolbar]);

  const updateSigningObject = (updatedObject) => {
    const defaultWidth = getDefaultFieldWidth(updatedObject?.type);

    const newSigningLocations = allSigningLocations.map((obj) => {
      // fields before eSign is sent have id, fields after eSign is sent have esigning_location_id
      const hasSameId = obj.id && updatedObject.id && obj.id === updatedObject.id;
      const hasSameEsigningId =
        obj.esigning_location_id &&
        updatedObject.esigning_location_id &&
        obj.esigning_location_id === updatedObject.esigning_location_id;

      return hasSameId || hasSameEsigningId
        ? {
            ...obj,
            ...updatedObject,
            ...(featureEnabled("toggle_files_esign_improvements") &&
              ((hasEsignImprovementsBeta && !esignAlreadySentOldFields) ||
                (!hasEsignImprovementsBeta && esignAlreadySentNewFields)) && {
                width: updatedObject.width || defaultWidth,
                height: updatedObject.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
              }),
          }
        : obj;
    });

    setSigningLocations(newSigningLocations);
  };

  return signingLocations
    .filter((signingObject) =>
      isClient || preAuthSigner
        ? (signingObject.value && signingObject.completed_at) ||
          userMatchesSigningLocation(signingObject, context.loggedInUser)
        : true
    )
    .map((signingObject) => {
      const objectId = signingObject.id || signingObject.esigning_location_id;
      const key = `${objectId}${signingObject?.signatory_user_id}`;
      const innerRef = React.createRef();
      signingObject.ref = innerRef;
      const type = signingObject.type;

      const defaultWidth = getDefaultFieldWidth(type);
      const newSigningObject = {
        id: key,
        ...signingObject,
        ...getFilledSignature(loggedInUserSignature, signingObject),
        cpIcon:
          type === "signature"
            ? "communication-signature"
            : type === "initials"
              ? "communication-initials"
              : type === "date"
                ? "misc-calendar"
                : "misc-text-input",
      };
      // Update parent state with new dimensions
      if (featureEnabled("toggle_files_esign_improvements") && hasEsignImprovementsBeta && !esignAlreadySentOldFields) {
        const needsUpdate = !signingObject.width || !signingObject.height;

        if (needsUpdate) {
          Object.assign(newSigningObject, {
            width: signingObject.width || defaultWidth,
            height: signingObject.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
            adjustY: true,
          });
          updateSigningObject(newSigningObject);
        }
      }

      return (
        <React.Fragment key={signingObject?.signatory_user_id ? key : objectId}>
          <DragWrapper
            canDrag={signingLocationsAreDraggable}
            signingObject={newSigningObject}
            clientCollaborators={clientCollaborators}
            allUsers={signersContext}
          >
            {esignAlreadySentOldFields ||
            !featureEnabled("toggle_files_esign_improvements") ||
            (featureEnabled("toggle_files_esign_improvements") &&
              !hasEsignImprovementsBeta &&
              !esignAlreadySentNewFields) ? (
              <NewDraggableSigningField
                allUsers={signersContext}
                clientCollaborators={clientCollaborators}
                context={context}
                deleteSigningField={deleteSigningField}
                openSignatureEntryModal={() => openSignatureEntryModal(signingObject, objectId)}
                setSigner={setSigner}
                showSignerDropdown={showSignerDropdown}
                signingLocationsAreDraggable={signingLocationsAreDraggable}
                signingObject={newSigningObject}
              />
            ) : (
              <SignerFieldToolbar
                allUsers={signersContext}
                clientCollaborators={clientCollaborators}
                context={context}
                deleteSigningField={deleteSigningField}
                documentRef={documentRef}
                duplicateSigningField={duplicateSigningField}
                hasEsignImprovementsBeta={hasEsignImprovementsBeta}
                isActive={activeToolbarId === signingObject.id}
                isTemplateMode={isTemplateMode}
                markFieldAsSigned={markFieldAsSigned}
                handleActiveToolbarOpen={() => handleActiveToolbarOpen(signingObject.id)}
                openSignatureEntryModal={() => {
                  openSignatureEntryModal(signingObject, objectId);
                }}
                onDropdownRef={onDropdownRef}
                setSigner={setSigner}
                showSignerDropdown={showSignerDropdown}
                signingLocationsAreDraggable={signingLocationsAreDraggable}
                signingObject={newSigningObject}
                updateSigningObject={updateSigningObject}
                updateSigningObjectFieldType={updateSigningObjectFieldType}
              />
            )}
          </DragWrapper>
        </React.Fragment>
      );
    });
};
