import React, { useContext, useCallback } from "react";
import { useCss, k, a } from "kremling";
import { useDebounce } from "canopy-styleguide!sofe";
import { CompletedField } from "../completed-field.component";
import { SigningContext } from "../../signing-context";
import { PlaceholderText } from "./draggable-resizable-field-placeholder.component";

export const DraggableResizableTextField = ({
  completedFieldRef,
  context,
  dropdownRef,
  forCurrentUser,
  handleClickShowToolbar,
  isTeamMember,
  isTextField,
  resizableFieldFontSize,
  setFieldIsActive,
  setTextFieldValue,
  showActiveSignerFieldExtras,
  signerFieldProps,
  signingLocationsAreDraggable,
  signingObject,
  size,
  textareaRef,
  textFieldValue,
  updateSigningObject,
}) => {
  const scope = useCss(css);
  const { nextLocation, setNextLocation, updateFieldValue } = useContext(SigningContext);

  const updateTextFieldAndNextLocation = useCallback(
    (value, updateParent = false) => {
      const updatedObject = {
        ...signingObject,
        value: value,
        hasBeenSigned: value.length > 0,
        // if fields are draggable then we need to update the dimensions/positioning
        ...(signingLocationsAreDraggable && {
          height: signingObject.height,
          width: signingObject.width,
          x: signingObject.x,
          y: signingObject.y,
        }),
      };
      // only want to update the parent if the field is blurred and not being edited
      if (updateParent) updateSigningObject(updatedObject);

      if (!signingLocationsAreDraggable && nextLocation?.esigning_location_id === signingObject.esigning_location_id) {
        setNextLocation({
          ...nextLocation,
          value: value,
          hasBeenSigned: value.length > 0,
        });
      }
    },
    [signingObject, updateSigningObject, signingLocationsAreDraggable, nextLocation, setNextLocation]
  );

  const debouncedTextFieldUpdate = useDebounce(updateTextFieldAndNextLocation, 300, {}, [
    updateTextFieldAndNextLocation,
  ]);

  const handleTextChange = (e) => {
    const inputValue = e.target.value;
    if (!isTextField || !forCurrentUser) return;
    if (!textareaRef.current) return;
    const wouldOverflow = textareaRef.current.scrollHeight > textareaRef.current.clientHeight;

    if (!wouldOverflow) {
      setTextFieldValue(inputValue);
      // update value on the ref for validation if the field is still focused
      updateFieldValue(signingObject.esigning_location_id, inputValue);
      debouncedTextFieldUpdate(inputValue);
    }
  };

  const handleTextBlur = () => {
    if (!isTextField || !forCurrentUser) return;
    if (!textareaRef.current) return;

    const trimmedValue = textFieldValue.trim();
    setTextFieldValue(trimmedValue);
    updateTextFieldAndNextLocation(trimmedValue, true);
  };

  return (
    <div {...scope}>
      {/* text fields should be editable until doc has been sent and signed is true */}
      {signingObject.signed ? (
        <CompletedField
          {...signerFieldProps}
          context={context}
          forCurrentUser={forCurrentUser}
          handleClickShowToolbar={handleClickShowToolbar}
          signingObject={signingObject}
          completedFieldRef={completedFieldRef}
          resizableFieldFontSize={resizableFieldFontSize}
        />
      ) : (
        <div>
          {forCurrentUser && (
            <>
              <PlaceholderText
                context={context}
                dropdownRef={dropdownRef}
                fieldHasBeenSigned={signingObject.signed}
                forCurrentUser={forCurrentUser}
                isDateObject={false}
                isSignatureObject={false}
                isTeamMember={isTeamMember}
                isTextField={isTextField}
                setFieldIsActive={setFieldIsActive}
                showActiveSignerFieldExtras={showActiveSignerFieldExtras}
                signingLocationsAreDraggable={signingLocationsAreDraggable}
                textFieldValue={textFieldValue}
              />
              <textarea
                ref={textareaRef}
                value={textFieldValue}
                onChange={handleTextChange}
                onBlur={handleTextBlur}
                rows={Math.max(1, Math.floor(size.height / 16))}
                className={a("custom-textarea cp-caption").t(
                  "custom-textarea-active",
                  "custom-textarea-inactive",
                  forCurrentUser
                )}
                style={{ height: `${size.height}px` }}
                onClick={handleClickShowToolbar}
              />
            </>
          )}
        </div>
      )}
      {!forCurrentUser && <div className="textarea-overlay" onClick={handleClickShowToolbar} />}
    </div>
  );
};

const css = k`
    .custom-textarea {
    position: relative;
    display: flex;
    flex: 1;
    width: 100%; 
    min-height: 18px !important;
    padding: 0;
    border: none;
    background-color: transparent;
    color: var(--cp-color-app-primary-text);
    outline: none;
    box-shadow: none !important;
    box-sizing: border-box;
    overflow: hidden;
    opacity: 1;
    z-index: 4;
    pointer-events: auto;
    resize: none;
    white-space: pre-line;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .custom-textarea-active {
    cursor: move;
  }
  .custom-textarea-active:focus {
    cursor: text;
  }
  .custom-textarea-inactive {
    cursor: move !important;
    user-select: none !important;
    pointer-events: none !important;
  }
  .textarea-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: move;
  }
`;
