import React from "react";
import { useCss, a, k } from "kremling";
import { ResizableFieldDimensions, signingFieldTypes } from "../../constants";
import { getDefaultFieldWidth } from "../../signing-resource.helper";

export const DraggableResizablePreview = ({ teamMember, item }) => {
  const defaultWidth = getDefaultFieldWidth(item.type);
  const scope = useCss(css);

  return (
    <div {...scope}>
      <div
        className={a("resizable-preview").m("resizable-preview-teamMember", teamMember)}
        style={{
          width: item?.width || defaultWidth,
          height: item?.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
          position: "relative",
          display: "flex",
          alignItems: item.type === signingFieldTypes.TEXT ? "flex-start" : "flex-end",
        }}
      >
        <div className="preview-background" />
        {item?.value && item?.hasBeenSigned && (
          <div
            className="preview-content"
            style={{
              fontSize: `${item.fontSize || 12}px`,
              fontFamily:
                item.type === signingFieldTypes.TEXT || item.type === signingFieldTypes.DATE
                  ? "Source Sans Pro"
                  : item.font,
              color: "var(--cp-color-app-primary-text)",
              position: "relative",
              zIndex: 1,
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: item.type === signingFieldTypes.TEXT ? "pre-line" : "nowrap",
            }}
          >
            {item.value}
          </div>
        )}
      </div>
    </div>
  );
};

const css = k`
  .resizable-preview {
    border: 1px solid var(--cp-color-app-primary);
    position: relative;
  }

  .resizable-preview-teamMember {
    border-color: var(--cp-color-pill-team-text);
  }

  .preview-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cp-color-pill-bg);
    opacity: 0.5;
  }

  .resizable-preview-teamMember .preview-background {
    background-color: var(--cp-color-pill-team-bg);
  }

  .preview-content {
    position: relative;
    z-index: 1;
  }
`;
