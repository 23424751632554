import React from "react";
import styles from "./common-signing-field.styles.css";
import { CpIcon } from "canopy-styleguide!sofe";
import { signingFieldTypes } from "../constants.js";
import { convertDateToString, userMatchesSigningLocation } from "../signing-modal.helper.js";

export const CompletedField = (props) => {
  const { signingObject, context, teamMember, completedFieldRef, resizableFieldFontSize, openSignatureEntryModal } =
    props;
  const signDate = signingObject.completed_at;
  const forCurrentUser = userMatchesSigningLocation(signingObject, context.loggedInUser);
  const isDateObject = signingObject.type === signingFieldTypes.DATE;
  const isSignatureObject = signingObject.type === signingFieldTypes.SIGNATURE;
  const isInitialsObject = signingObject.type === signingFieldTypes.INITIAL;
  const isTextObject = signingObject.type === signingFieldTypes.TEXT;
  const hasBeenSigned = signingObject.hasBeenSigned || signingObject.signed;

  const currentUserSignatureOrInitialField = forCurrentUser && (isSignatureObject || isInitialsObject);

  return (
    <div
      ref={completedFieldRef}
      className={`${styles.filledSigningObjectField} ${styles.modalMode} ${isSignatureObject ? "cp-ellipsis" : ""}`}
      onClick={!isDateObject ? (forCurrentUser ? props.handleClickShowToolbar : null) : null}
      style={{
        fontSize: `${resizableFieldFontSize}px`,
        fontFamily: isSignatureObject || isInitialsObject ? signingObject.font : `"Source Sans Pro", sans-serif`,
        cursor: !isDateObject && forCurrentUser && !hasBeenSigned ? "pointer" : "default",
        paddingBottom:
          isDateObject || ((isSignatureObject || isInitialsObject) && signingObject.signed) ? "6px" : "0px",
        bottom: isTextObject ? "auto" : "0px",
      }}
    >
      {/* Display filled signature */}
      <div
        className={styles.signatureContainer}
        onClick={(e) => {
          e.stopPropagation();
          currentUserSignatureOrInitialField && openSignatureEntryModal();
        }}
      >
        <div className={isTextObject ? styles.textFieldValue : styles.signatureName}>
          {isDateObject ? convertDateToString(signDate) : signingObject.value}
        </div>
        {currentUserSignatureOrInitialField && signingObject.value && !signingObject.signed && (
          <CpIcon
            className="cp-ml-4 shrink-0"
            name="crud-pencil"
            size={16}
            fill={teamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)"}
          />
        )}
      </div>
    </div>
  );
};

CompletedField.displayName = "CompletedField";
