import React from "react";
import styles from "./kba-flow-manager.styles.css";
import Disposable from "react-disposable-decorator";
import PropTypes from "prop-types";
import { handleError } from "src/handle-error.helper";
import { createKbaInstance, submitKbaResponses, recordPhysicalSignature } from "./kba.resource";
import { CpLoader } from "canopy-styleguide!sofe";
import LoadingOnSubmit from "./questions/loading-onsubmit.component";
import { fromSigningObservable } from "../esign/esign-container.component";

const KbaIcon = ({ icon, isActive, isCurrent, children, stepFailed }) => {
  const circleIconStyle = { width: 40, height: 40, borderRadius: "50%" };
  return (
    <div className={`${styles.alignIcon}`}>
      <span
        className={`cps-margin-right-8 ${isCurrent ? `${styles.kbaFlowIconBorder} cps-depth-2` : ""}`}
        aria-hidden={true}
      >
        {stepFailed ? (
          <span className={`cps-icon +round cps-white cps-icon-close cps-bg-color-red`} style={circleIconStyle} />
        ) : (
          <span
            className={`cps-icon +round cps-white cps-icon-${isActive && !isCurrent ? "lg-check" : icon} ${
              isActive ? `cps-bg-color-green` : "cps-bg-color-cool-gray"
            }`}
            style={circleIconStyle}
          />
        )}
      </span>
      {children}
    </div>
  );
};

@Disposable
export default class KbaFlowManager extends React.Component {
  static propTypes = {
    documentId: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    attemptsRemaining: PropTypes.number,
    verifyKba: PropTypes.func.isRequired,
    userAlreadySigned: PropTypes.bool,
    taxPrepManualSign: PropTypes.bool,
  };

  state = {
    currentStep: 0,
    kbaInstance: null,
    isKbaVerified: null,
    validPerson: false,
    loadingContent: false,
    attemptsRemaining: this.props.attemptsRemaining,
    person: {},
  };

  nextStep = () => {
    const maxStepIndex =
      this.props.children({
        next: this.nextStep,
        getKbaInstance: this.getKbaInstance,
        attemptsRemaining: this.state.attemptsRemaining,
      }).length - 1;
    let currentStep = this.state.currentStep + 1;
    let kbaInProgress = this.state.currentStep !== 0;

    // User has failed the first auth step, attemptsRemaining is above 0, bring them back to the start of auth flow
    const failedFirstAuthStep =
      !this.state.validPerson && this.state.currentStep > 0 && this.state.attemptsRemaining > 0;
    // User is going through KBA Question flow and fails, but attemptsRemaining is above 0. Direct them to step 2 (question set)
    const failedKBAQuestionFlow =
      currentStep > maxStepIndex && this.state.attemptsRemaining > 0 && !this.state.isKbaVerified;
    // User failed KBA and has 0 attempts remaining. Kick them over to the failure flow and start at step 0 (if they are not on step 0 already)
    const failedKBA = this.state.attemptsRemaining === 0 && !this.state.isKbaVerified && kbaInProgress;
    if (failedFirstAuthStep || failedKBA) {
      currentStep = 0;
    } else if (failedKBAQuestionFlow) {
      currentStep = 2;
    }
    this.setState({ currentStep, loadingContent: false });
  };

  getKbaInstance = (person) => {
    this.setState({ loadingContent: true, person });
    this.props.cancelWhenUnmounted(
      createKbaInstance(this.props.documentId, person, this.props.signerId).subscribe(
        (kbaInstance) => {
          this.setState({ kbaInstance, loadingContent: false, isKbaVerified: null, validPerson: true }, this.nextStep);
        },
        (error) => {
          if (error.data.kba_error === "invalid_person") {
            error.toastMessage =
              "We could not validate the information entered. Please check your information and resubmit.";
            error.skipLogging = true;
          }
          this.setState(
            { loadingContent: false, attemptsRemaining: error.data?.kba_attempts_remaining },
            this.nextStep
          );
          handleError(error);
        }
      )
    );
  };

  submitManualSignature = (file) => {
    this.setState({ loadingContent: true });
    this.props.cancelWhenUnmounted(
      recordPhysicalSignature(this.props.documentId, file.id, this.props.signerId).subscribe(() => {
        fromSigningObservable.next({ action: "MANUAL_SIGN" });
        this.nextStep();
      }, handleError)
    );
  };

  submitResponses = (answers) => {
    this.setState({ loadingContent: true });
    const kbaInstance = this.state.kbaInstance;
    this.props.cancelWhenUnmounted(
      submitKbaResponses(
        this.props.documentId,
        kbaInstance.person.id,
        kbaInstance.questions.id,
        answers,
        this.props.signerId
      ).subscribe((isKbaVerified) => {
        this.setState(
          (prevState) => {
            const currentRemaining = prevState.attemptsRemaining - 1;
            return { isKbaVerified, loadingContent: false, attemptsRemaining: currentRemaining };
          },
          () => {
            if (isKbaVerified) {
              this.props.verifyKba(isKbaVerified);
            } else {
              this.nextStep();
            }
          }
        );
      }, handleError)
    );
  };

  retryAfterFailedAttempt = () => {
    this.getKbaInstance(this.state.person);
  };

  onTimerComplete = () => {
    this.setState({ isKbaVerified: false }, this.nextStep);
  };

  render() {
    const { taxPrepManualSign, phoneOnly } = this.props;
    const children = this.props.children({
      next: this.nextStep,
      getKbaInstance: this.getKbaInstance,
      kbaInstance: this.state.kbaInstance,
      submitResponses: this.submitResponses,
      isKbaVerified: this.state.isKbaVerified,
      onTimerComplete: this.onTimerComplete,
      retryAfterFailedAttempt: this.retryAfterFailedAttempt,
      attemptsRemaining: this.state.attemptsRemaining,
      submitManualSignature: this.submitManualSignature,
    });

    return (
      <div className={`${styles.kbaCardCentered}`}>
        <div className={`${styles.kbaCard} cps-card cps-padding-8`}>
          {!taxPrepManualSign && !phoneOnly && this.state.attemptsRemaining > 0 && !this.props.userAlreadySigned && (
            <div className="cps-well cps-padding-16" style={{ display: "flex", justifyContent: "space-between" }}>
              <KbaIcon icon="contacts" isActive={this.state.currentStep >= 1} isCurrent={this.state.currentStep === 1}>
                <span>
                  <span className="cps-wt-bold">1.</span> Enter your ID
                </span>
              </KbaIcon>
              <KbaIcon
                icon="survey"
                isActive={this.state.currentStep >= 2}
                stepFailed={!this.state.isKbaVerified && this.state.isKbaVerified !== null}
                isCurrent={this.state.currentStep === 2}
              >
                <span>
                  <span className="cps-wt-bold">2.</span> Verify your ID
                </span>
              </KbaIcon>
              <KbaIcon stepFailed={this.state.currentStep >= 3 && this.state.attemptsRemaining <= 0} icon="sign">
                <span>
                  <span className="cps-wt-bold">3.</span> eSign
                </span>
              </KbaIcon>
            </div>
          )}
          <div style={{ display: "flex", flex: "1 0 100%", justifyContent: "center" }}>
            {this.state.loadingContent && this.state.currentStep !== 2 && (
              <div className={`${styles.loaderContainer}`}>
                <CpLoader width="sm" />
              </div>
            )}
            {this.state.loadingContent && this.state.currentStep === 2 && this.state.currentStep < 3 && (
              <LoadingOnSubmit />
            )}
            {!this.state.loadingContent && children[this.state.currentStep]}
          </div>
        </div>
      </div>
    );
  }
}

KbaFlowManager.defaultProps = {
  attemptsRemaining: 0,
};
