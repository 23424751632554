import React, { useEffect, useContext, useState } from "react";
import { CpButton, CpInlineNotification } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { EsignContext } from "../../../signing-context";
import { mapSigningLocationsForTemplate, remapSigningLocationsToRatio } from "../../../signing-modal.helper";
import { patchEsignTemplate, postEsignTemplate } from "../../../signing.resource";
import { warningToast, successToast } from "toast-service!sofe";
import { useWithUserAndTenant, useBetas } from "cp-client-auth!sofe";
import { handleError } from "src/handle-error.helper";
import { templateTypes, signingFieldTypes } from "../../../constants";

export const CreateTemplateButton = ({
  signingLocations,
  descriptionError,
  documentSizes,
  closeModal,
  kbaEnabled,
  errorMessage,
}) => {
  const { esignContext, setEsignContext } = useContext(EsignContext);
  const [sendData, setSendData] = useState(false);
  const [user] = useWithUserAndTenant();
  const isPreset = esignContext?.template_type === templateTypes.preset?.id;
  const hasEsignImprovementsBeta = useBetas("beta_files_esign_improvements");
  const checkEsignBetaAccess = featureEnabled("toggle_files_esign_beta") ? hasEsignImprovementsBeta : true;

  useEffect(() => {
    if (sendData) {
      if (signingLocations?.find((location) => !location?.signer_type_id)) {
        return warningToast("Please select a signer type for each signer");
      }

      const locations = remapSigningLocationsToRatio(signingLocations, documentSizes, checkEsignBetaAccess);
      const mappedLocations = locations?.map(mapSigningLocationsForTemplate);
      const template = {
        ...esignContext,
        user_id:
          esignContext.template_type === templateTypes.private?.id ||
          (esignContext.template_type === templateTypes.team?.id && esignContext.draft)
            ? user.id
            : null,
        signing_locations: mappedLocations || [],
        kba_enabled: kbaEnabled,
      };
      const saveEsignTemplateObservable = template?.id ? patchEsignTemplate : postEsignTemplate;

      const sub = saveEsignTemplateObservable(template).subscribe(() => {
        setSendData(false);
        successToast("Your eSign template was successfully saved.");
        closeModal(template);
      }, handleError);
      return () => sub.unsubscribe();
    }
  }, [sendData, kbaEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'checkEsignBetaAccess', 'closeModal', 'documentSizes', 'esignContext', 'signingLocations', and 'user.id'

  const saveEsignTemplate = (isDraft) => {
    setEsignContext({ ...esignContext, draft: isDraft });
    setSendData(true);
  };

  const unassignedLocation = signingLocations?.length < 1 ? false : signingLocations.find((loc) => !loc.signer_type_id);
  const missingTitleError = errorMessage === "Template title is required.";
  const disableSave = errorMessage?.includes("Team") || errorMessage?.includes("Private") || missingTitleError;

  const hasTextFields = signingLocations?.some((location) => location.type === signingFieldTypes.TEXT);
  const textFieldErrorMessage =
    (!featureEnabled("toggle_files_esign_improvements") || !checkEsignBetaAccess) && hasTextFields
      ? "There is an error with text fields. Remove them to continue editing this template."
      : null;

  return (
    <>
      <div className="cp-flex-center cp-mt-16 cp-pt-12 justify-end">
        {esignContext.draft && !isPreset && (
          <CpButton
            disabled={unassignedLocation || errorMessage?.includes("Draft") || descriptionError || missingTitleError}
            btnType="flat"
            className="cp-mr-16"
            onClick={() => saveEsignTemplate(true)}
          >
            Save as draft
          </CpButton>
        )}
        <CpButton
          disabled={unassignedLocation || disableSave || descriptionError || !!textFieldErrorMessage}
          onClick={() => saveEsignTemplate(false)}
        >
          {/*TODO: remove preset? adding this in for internal, since I keep getting confused */}
          Save {isPreset && "preset "}template
        </CpButton>
      </div>
      {(unassignedLocation || signingLocations.length < 1) && (
        <div className="cp-pt-24">
          <CpInlineNotification
            message={
              signingLocations.length < 1
                ? "There are no signing fields on this template."
                : "All signing fields must be assigned to a signer role."
            }
          />
        </div>
      )}
      {!!textFieldErrorMessage && (
        <div className="cp-pt-24">
          <CpInlineNotification message={textFieldErrorMessage} type="error" />
        </div>
      )}
    </>
  );
};
