import React, { useContext } from "react";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { useDrop, useDragLayer } from "react-dnd";
import { dragTypes, signingFieldTypes } from "../constants";
import { dropSigningField } from "./document-view.component";
import { useCss, k } from "kremling";
import { SignerPill } from "../esign/page-layout-components/signer-pill.component";
import { SigningContext } from "../signing-context";
import { DraggableResizablePreview } from "../signing-items/draggable-resizable-field-components/draggable-resizable-preview.component";

export const DocumentDropWrapper = (props) => {
  const [, drop] = useDrop(
    () => ({
      accept: [dragTypes.DRAG_FIELD, dragTypes.SIGNING_FIELD],
      drop(_item, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) return;
        return dropSigningField(_item, monitor, { ...props });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [props.children]
  );
  return <div ref={drop}>{React.cloneElement(props.children)}</div>;
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const getItemStyles = (
  currentOffset,
  item,
  esignAlreadySentOldFields,
  esignAlreadySentNewFields,
  hasEsignImprovementsBeta
) => {
  if (!currentOffset) {
    return {
      display: "none",
    };
  }
  const { x, y } = currentOffset;

  const isExistingField =
    featureEnabled("toggle_files_esign_improvements") &&
    ((hasEsignImprovementsBeta && !esignAlreadySentOldFields) ||
      (!hasEsignImprovementsBeta &&
        esignAlreadySentNewFields &&
        typeof item.x !== "undefined" &&
        typeof item.y !== "undefined"));
  // adjust drag position for existing resizable fields
  const transform = `translate(${x}px, ${y - (isExistingField ? item.height : 0)}px)`;

  return {
    transform,
    filter: isSafari ? "none" : "drop-shadow(0 2px 12px rgba(0,0,0,0.45))",
  };
};

export const DragLayer = () => {
  const scope = useCss(css);
  const [user] = useWithUserAndTenant();
  const {
    signerTypesContext,
    signersContext,
    esignAlreadySentOldFields,
    esignAlreadySentNewFields,
    hasEsignImprovementsBeta,
  } = useContext(SigningContext);

  const { itemType, isDragging, item, currentOffset, pointerOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(), //the offset of the drag item
    pointerOffset: monitor.getClientOffset(), //the offset of the pointer event
    isDragging: monitor.isDragging(),
  }));

  if (!item) return null;

  const isMySignature = user.id === item?.signatory_user_id;
  const isSignatureObject = item?.type === signingFieldTypes.SIGNATURE;
  const isInitialObject = item?.type === signingFieldTypes.INITIAL;
  const isTextFieldObject = item?.type === signingFieldTypes.TEXT;
  const isDateObject = item?.type === signingFieldTypes.DATE;
  const itemID = item.signer_type_id || item.signatory_user_id;

  const assignedTo = [...signerTypesContext, ...signersContext].find((signer) => signer.id === itemID);
  const isTeamMember = item.role
    ? item.role === "TeamMember"
    : assignedTo?.role === "TeamMember" || assignedTo?.user_role === "TeamMember";

  if (!isDragging) {
    return null;
  }
  if (
    (!featureEnabled("toggle_files_esign_improvements") ||
      (featureEnabled("toggle_files_esign_improvements") && !hasEsignImprovementsBeta)) &&
    item.value &&
    item.completed_at
  )
    return null;
  let itemText = item?.text;

  if (isSignatureObject) {
    if (isMySignature) {
      itemText = "My Signature";
    } else {
      itemText = item?.name || item?.signer_type_name || "Recipient Signature";
    }
  }

  if (isInitialObject) itemText = "Initial";
  if (isTextFieldObject) itemText = "Text field";
  if (isDateObject) itemText = "Date";

  if (!isMySignature && !isSignatureObject && item?.name) itemText += `: ${item?.name}`;

  const isExistingField = typeof item.x !== "undefined" && typeof item.y !== "undefined";
  // use existing pill preview for initial new field drop
  const useResizablePreview =
    featureEnabled("toggle_files_esign_improvements") &&
    hasEsignImprovementsBeta &&
    !esignAlreadySentOldFields &&
    isExistingField;

  return (
    <div {...scope} className="drag-layer-wrapper">
      <div
        style={getItemStyles(
          itemType === "dragField" ? pointerOffset : currentOffset,
          item,
          esignAlreadySentOldFields,
          hasEsignImprovementsBeta
        )}
      >
        {useResizablePreview ? (
          <FieldDragPreview teamMember={isTeamMember} item={item} />
        ) : (
          <PillDragPreview teamMember={isTeamMember} type={itemType} item={item} itemText={itemText} />
        )}
      </div>
    </div>
  );
};

const PillDragPreview = ({ item, itemText, teamMember }) => (
  <div className="drag-layer">
    <SignerPill
      dragHandle
      icon={item.cpIcon}
      className="drag-layer-drag-pill"
      teamMember={teamMember}
      text={itemText}
    />
  </div>
);

const FieldDragPreview = ({ item, teamMember }) => (
  <div className="drag-layer">
    <DraggableResizablePreview item={item} teamMember={teamMember} className="drag-layer-drag-pill" />
  </div>
);

const css = k`
  .drag-layer-wrapper {
    position: fixed;
    pointer-events: none;
    z-index: 10000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .drag-layer-drag-pill {
    position: fixed;
  }
  
  .drag-layer {
    position: fixed;
    pointer-events: none;
    z-index: 1000;
    left: 0;
    top: 0;
  }
`;
