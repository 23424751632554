import React from "react";
import { featureEnabled } from "feature-toggles!sofe";
import { ResizableFieldDimensions } from "./constants";

export function isClient(context) {
  return context.loggedInUser.role === "Client";
}

export function convertDateToString(dateString) {
  const signDateObj = new Date(dateString);
  return `${signDateObj.getMonth() + 1}/${signDateObj.getDate()}/${signDateObj.getFullYear()}`;
}

// This offset is to account for the difference between the coordinate point
// and the visual styling of the signature box.
// Should equal the padding of `.filledSigningObject` css class.
const SIGNINGOBJ_PADDING_OFFSET = 8;

// This offset is to account for the difference between the drag handle and the start of the actual signature placement.
// Should equal the padding of .filledSigningObject css class.
// const DRAG_HANDLE_WIDTH = 14;

export function remapSigningLocationsFromRatio(
  signingLocations,
  pageNum,
  { width, height },
  hasEsignImprovementsBeta = null
) {
  // remaps signingLocations for a specific page when LOADING an existing eSign file or template
  return signingLocations.map((loc) => {
    if (loc.page === pageNum) {
      // Assumption: 0-1 inclusive reserved as range for ratios
      let { x, y } = loc;
      // adjust Y if needed since new fields are positioned by bottom edge and not top edge
      // 0 is top edge, 1 is bottom edge and we are not allowing fields off the top or bottom edge
      if (y > 1) {
        y = 1;
      }
      const xIsRatio = x - 1 < 0;
      const yIsRatio = y - 1 < 0;
      const hasHeightWidth = loc.height && loc.width;
      // if we are displaying old eSign fields, use SIGNINGOBJ_PADDING_OFFSET
      // if we are displaying new eSign fields, use 0 (filledSigningObjectField class has no padding)
      let paddingOffset = 0;
      if (
        !featureEnabled("toggle_files_esign_improvements") ||
        (featureEnabled("toggle_files_esign_improvements") && !hasEsignImprovementsBeta && !hasHeightWidth)
      ) {
        // if feature is off, or if beta is off and the fields are old fields (no height or width) use old padding
        paddingOffset = SIGNINGOBJ_PADDING_OFFSET;
      } else if (
        featureEnabled("toggle_files_esign_improvements") &&
        hasEsignImprovementsBeta &&
        (!hasHeightWidth || loc.adjustY)
      ) {
        // if feature & beta is on, and the fields are old fields (no height or width) or need adjustY use old padding
        paddingOffset = SIGNINGOBJ_PADDING_OFFSET;
      }

      // old fields do not have height but would not be resized so use default height
      const fieldHeight = loc.height || ResizableFieldDimensions.DEFAULT_HEIGHT;

      let newX = (xIsRatio ? x * width : x) - paddingOffset;
      let newY = (yIsRatio ? y * height : y) - paddingOffset;

      if (featureEnabled("toggle_files_esign_improvements")) {
        if (hasEsignImprovementsBeta) {
          // if a field has adjustY then we are editing an old eSign and we need to adjust the position
          // adjustY is only added when the eSign has old fields (no height or width) and it HAS NOT been sent
          if (loc.adjustY) {
            newY = newY + 32;
          } else if (newY === 1) {
            // if newY === 1 then that means the bottom edge of the field is at the bottom of the page
            // so set the newY to the height of the page so bottom edge of field is at the bottom of the page
            newY = height;
          }
          if (newY > height || newY < fieldHeight) {
            // if newY is greater than the height of the page, align the bottom of the field to the bottom of the page
            // since new fields are positioned by bottom edge and not top edge: newY cannot be smaller than fieldHeight
            // so if newY < fieldHeight, align the bottom of the field to the bottom of the page
            newY = height;
          }
        }
        if (!hasEsignImprovementsBeta && hasHeightWidth && !loc.esigning_location_id) {
          // if the beta is off, we need to adjust the position of the field to account for the difference in positioning
          newY = newY - 32;
        }
      }
      // if the toggle is off but the fields have height/width then they were edited with the feature/beta on
      // we need to adjust their location to account for the difference in positioning now that the feature is off
      if (!featureEnabled("toggle_files_esign_improvements") && hasHeightWidth) {
        if (hasEsignImprovementsBeta) {
          newY = newY - 24;
          newX = newX + 8;
        }
      }
      return { ...loc, x: newX, y: newY };
    } else {
      return loc;
    }
  });
}

export function remapAllSigningLocationsFromRatio(signingLocations, documentSizes, hasEsignImprovementsBeta = null) {
  // remap all signingLocations when APPLYING an existing eSign Template

  return signingLocations.map((loc) => {
    if (!documentSizes[loc?.page]) {
      return;
    }
    const { width, height } = documentSizes[loc.page];
    // Assumption: 0-1 inclusive reserved as range for ratios
    let { x, y } = loc;
    // adjust Y if needed since new fields are positioned by bottom edge and not top edge
    // 0 is top edge, 1 is bottom edge and we are not allowing fields off the top or bottom edge
    if (y > 1) {
      y = 1;
    }
    const xIsRatio = x - 1 < 0;
    const yIsRatio = y - 1 < 0;

    const hasHeightWidth = loc.height && loc.width;
    const paddingOffset =
      featureEnabled("toggle_files_esign_improvements") && hasEsignImprovementsBeta && hasHeightWidth
        ? 0
        : SIGNINGOBJ_PADDING_OFFSET;

    // old fields do not have height but would not be resized so use default height
    const fieldHeight = loc.height || ResizableFieldDimensions.DEFAULT_HEIGHT;

    let newX = (xIsRatio ? x * width : x) - paddingOffset;
    let newY = (yIsRatio ? y * height : y) - paddingOffset;

    // if a field has adjustY then it was from an old eSign and we need to adjust the position
    if (featureEnabled("toggle_files_esign_improvements") && hasEsignImprovementsBeta) {
      // New fields are only allowed to be placed ON the height of the current page (not off top or bottom edge)
      // Previously we allowed fields to be positioned off the bottom edge of a page.
      // When loading an old esign template, we need to adjust the position of the field to move fields on the page
      // and also account for positioning by bottom edge.
      if (loc.adjustY || !hasHeightWidth) {
        // if a field has adjustY then it was from an old eSign document (not template) and we need to adjust the position
        newY = newY + 32;
      } else if (newY === 1) {
        // if newY === 1 then that means the bottom edge of the field is at the bottom of the page
        // so set the newY to the height of the page so bottom edge of field is at the bottom of the page
        newY = height;
      }
      if (newY > height || newY < fieldHeight) {
        // if newY is greater than the height of the page, align the bottom of the field to the bottom of the page
        // since new fields are positioned by bottom edge and not top edge: newY cannot be smaller than fieldHeight
        // so if newY < fieldHeight, align the bottom of the field to the bottom of the page
        newY = height;
      }
    }
    // if the toggle is off but the fields have height/width then they were edited with the toggle on
    // we need to adjust their location to account for the difference in positioning
    if (
      (!featureEnabled("toggle_files_esign_improvements") && hasHeightWidth) ||
      (featureEnabled("toggle_files_esign_improvements") && !hasEsignImprovementsBeta && hasHeightWidth)
    ) {
      newY = newY - 24;
      newX = newX + 8;
    }
    return { ...loc, x: newX, y: newY };
  });
}

export function remapSigningLocationsToRatio(signingLocations, documentSizes, hasEsignImprovementsBeta = null) {
  // remap signingLocations before sending them to the BE by clicking either sending an eSign
  // OR clicking the save button when creating/editing an eSign template

  // Before sending to BE we need to check the status of the toggle_files_esign_improvements feature toggle
  // if the feature toggle is off, we need to remove the height and width from the field locations if they exist
  // before saving the template so when the eSign is edited or applied the fields have the correct locations
  const isEsignImprovementsEnabled = featureEnabled("toggle_files_esign_improvements") && hasEsignImprovementsBeta;
  const updatedLocations = signingLocations?.map((loc) => {
    const { height: fieldHeight, width: fieldWidth, ...locationWithoutDimensions } = loc;
    const updatedLocation = isEsignImprovementsEnabled ? loc : locationWithoutDimensions;

    const { width, height } = documentSizes[loc.page];
    let { x, y } = updatedLocation;

    const hasHeightWidth = updatedLocation.height && updatedLocation.width;
    const paddingOffset =
      featureEnabled("toggle_files_esign_improvements") && hasEsignImprovementsBeta && hasHeightWidth
        ? 0
        : SIGNINGOBJ_PADDING_OFFSET;
    const newX = (x + paddingOffset) / width;
    const newY = (y + paddingOffset) / height;
    return { ...updatedLocation, x: newX, y: newY };
  });
  return updatedLocations;
}

export const searchHighlight = (string, term) => {
  if (!term || !string) return string;
  term = term.toLowerCase();
  const stop = string.toLowerCase().indexOf(term);
  if (stop === -1) return string;
  const bold = string.slice(stop, stop + term.length);
  const nonBold = string.slice(0, stop);
  const rest = string.slice(stop + term.length, string.length);
  return (
    <span>
      {nonBold}
      <strong className="cps-color-primary">{bold}</strong>
      {searchHighlight(rest, term)}
    </span>
  );
};

//this is the same classname that the CpModal from canopy styleguide uses to hide background scroll
const hideScrollbarClassName = "--esign-hide-scroll-bar";

export const __openModal = (hideScroll = true) => {
  // run when esign component mounts
  if (hideScroll) {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.classList.add(hideScrollbarClassName);
    document.body.style.marginRight = `${scrollbarWidth / 10}rem`;
  }
};
export const __closeModal = () => {
  //run when esign component unmounts
  document.body.classList.remove(hideScrollbarClassName);
  document.body.style.marginRight = "initial";
};

export function userMatchesSigningLocation(signingLocation, user) {
  if (signingLocation.signer_id) {
    return signingLocation.signer_id === user.id && signingLocation.signatory_user_id === user.user_id;
  } else {
    return signingLocation.signatory_user_id === user.id;
  }
}

export function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export const getContactType = (user) => {
  return user?.contact_type === "primary" ? "Primary Contact" : capitalizeFirstLetter(user?.contact_type);
};

export const transformUsersForSelect = (user, signerTypes, isNewCrm) => {
  const prepend = user?.isSelf ? "(You) " : "";
  const overline = isNewCrm ? { overline: getContactType(user) } : {};
  return {
    id: user.id,
    name: `${prepend}${user.name}`,
    subName: user?.email,
    disabled: !!signerTypes?.find((type) => type?.assignedTo?.id === user?.id),
    ...overline,
  };
};

export const mapSigningLocationsForTemplate = (location) => ({
  page: location.page,
  x: location.x,
  y: location.y,
  type: location.type,
  signer_type_id: location.signer_type_id,
  height: location.height,
  width: location.width,
  // id: location.id, //backend does not allow
});

export const groupDataForSelect = (groupKeys) => {
  return Object.keys(groupKeys).map((key) => ({
    id: key,
    name: groupKeys[key]?.name,
    key: groupKeys[key]?.key,
    ...(groupKeys[key]?.icon && { icon: groupKeys[key].icon }),
  }));
};
