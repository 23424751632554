import React from "react";
import PropTypes from "prop-types";
import ReactDOMClient, { createRoot } from "react-dom/client";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { partial, findIndex, noop, cloneDeep } from "lodash";
import { handleError } from "src/handle-error.helper.js";
import singleSpaReact from "single-spa-react";
import { ErrorBoundary } from "error-logging!sofe";

import DocumentView from "../page-view-components/document-view.component.js";
import HeaderBar from "./letter-subcomponent/letter-header-bar.component.js";
import LettersSidePanel from "./letter-subcomponent/letters-side-panel.component.js";
import SignatureEntryModal from "../signature-entry/signature-entry-modal.component.js";
import ClientRequestSuccessModal from "../client-request/client-request-success-modal.component.js";
import EsignRequestSuccessModal from "./letter-subcomponent/letter-esign-request-success-modal.component.js";
import { signatureElements, tabs, signingFieldTypes , featureToggles, clientTypes } from "../constants.js";
import {
  isClient,
  remapSigningLocationsFromRatio,
  remapSigningLocationsToRatio,
  __openModal,
  __closeModal,
  userMatchesSigningLocation,
} from "../signing-modal.helper.js";
import styles from "../signing-modal.styles.css";
import {
  createSigningDocs,
  getSigningDocPages,
  createSigningLocations,
  signAllUserLocations,
  getSigningLocations,
  getClientCollaborators,
  getFileEsignSvgs,
  postEsignatureForDoc,
  getSigners,
} from "../signing.resource.js";
import { EsignContext, SigningContext } from "../signing-context";
import {
  createClientRequest,
  sendClientRequest,
  getClientRequest,
  updateClientRequest,
} from "../client-request/client-request.resource.js";
import { translateXfromPDF, translateYfromPDF } from "../signing-resource.helper";
import toasts from "toast-service!sofe";
import Disposable from "react-disposable-decorator";
import { CpLoader, CpEmptyState } from "canopy-styleguide!sofe";
import { forkJoin, from } from "rxjs";

import { track } from "src/analytics-helper.js";

@Disposable
export class LetterSignModal extends React.Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    context: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    documentType: PropTypes.oneOf(["letter", "file"]),
    signingId: PropTypes.string,
    resolutionCaseId: PropTypes.number,
    notificationIds: PropTypes.array,
    clientRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    document: PropTypes.object,
    esignDocument: PropTypes.shape({
      svg_urls: PropTypes.array,
      consented_to_electronic_records: PropTypes.bool,
      signing_locations: PropTypes.array,
      completed: PropTypes.bool,
    }),
  };

  constructor(props) {
    super(props);

    this.phoneOnly = window.matchMedia(
      "screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 599px) and (orientation: landscape)"
    ).matches;

    //Load partial signing data from previously closed signing experience
    this.storedSigningExperienceData = JSON.parse(sessionStorage.getItem(`partialSigningData_${props.documentId}`));

    const defaultDescription =
      "Please review and sign the letter below. It will be automatically sent to me when you've finished signing. Thanks!";

    this.state = {
      activeTab: tabs.SIGNATURE,
      signatureEntryModalType: signingFieldTypes.SIGNATURE,
      markFieldAsSigned: () => {},
      loggedInUserSignature: {
        id: props.context.loggedInUser.id,
        signatureText: null,
        initials: null,
        font: null,
        completed_at: null,
      },
      signingLocations: props.esignDocument ? props.esignDocument.signing_locations : [],
      clientCollaborators: {},
      consented: props.esignDocument ? props.esignDocument.consented_to_electronic_records : false,
      ...this.storedSigningExperienceData,
      signingRequestIsValid: false,
      signingId: props.signingId,
      //getSigningExperience (for letters) returns the data with a url property, so here we are matching the data structure so urls can be handled the same
      signingSVGs: props.esignDocument ? props.esignDocument.pdf_urls.map((url) => ({ url })) : null,
      loadedPages: 0,
      signingPagesLoaded: false,
      showSignatureEntryModal: false,
      showClientRequestCreatedSuccessfullyModal: false,
      showEsignRequestCreatedSuccessfullyModal: false,
      isClient: isClient(props.context),
      signingLocationsAreDraggable: !props.clientRequestId && !isClient(props.context),
      clientRequestData: null,
      documentCompleted: props.esignDocument ? props.esignDocument.completed : false,
      esignTaskId: null,
      documentSizes: [],
      signersContext: [],
      setSignersContext: this.setSignersContext,
      esignContext: {
        title: `eSign request: ${this.props.title}`,
        due_at: null,
        reminders: {
          reminder_interval: null,
          expiration_interval_days: null,
        },
        description: defaultDescription,
      },
      setEsignContext: this.setEsignContext,
      selectSignerNewUser: [],
    };

    this.setDocumentSizes = (pageNum, documentSize) => {
      const { documentSizes, signingLocations } = this.state;
      documentSizes[pageNum] = documentSize;
      const remappedSigningLocations = remapSigningLocationsFromRatio(signingLocations, pageNum, documentSize);
      this.setState({ documentSizes, signingLocations: remappedSigningLocations });
    };
  }

  componentDidMount() {
    __openModal();
    track("esign_letter_container");
    // determine and prep a few things up front
    // first, is this a continuation of a signing experience? (we have a signingId)
    this.createOrGetSigningExperience();
    this.signingRequestIsValid();

    if (!this.state.isClient) {
      this.setClientCollaborators(this.props.clientId);
    }

    if (!this.props.preAuthSigner && !this.state.isClient) {
      this.clientsAndSignersFetch = forkJoin({
        signersResponse: getSigners(this.props.clientId),
        clientsResponse: getClientCollaborators(this.props.clientId),
      }).subscribe((response) => {
        const {
          signersResponse,
          clientsResponse: {
            clients: { users },
          },
        } = response;
        const signingUsers = signersResponse
          .map((signer) => ({ ...signer, role: clientTypes.client, canEdit: true }))
          .filter((signer) => !users.find((user) => user.id === signer.user_id));
        this.setState({ signersContext: [...signingUsers, ...users] });
      }, handleError);
    }
  }

  componentDidUpdate() {
    !this.state.documentCompleted && this.signingRequestIsValid();
  }

  componentWillUnmount() {
    __closeModal();
  }

  setSignersContext = (signersContext) => {
    this.setState({ signersContext: [...signersContext] });
  };

  setEsignContext = (esignContext) => {
    this.setState({ esignContext: { ...esignContext } });
  };

  createOrGetSigningExperience = () => {
    const { documentId, documentType, cancelWhenUnmounted } = this.props;

    if (documentType === "file" && !this.state.signingSVGs) {
      cancelWhenUnmounted(
        from(getFileEsignSvgs(documentId)).subscribe(
          ({ urls }) => {
            const signingSVGs = urls.map((url) => ({ url }));
            this.setState({ signingSVGs });
          },
          (error) => {
            this.setState({
              esignError: true,
            });
            error.showToast = false;
            handleError(error);
          }
        )
      );
    } else if (documentType === "letter") {
      const { signingId, signingLocations } = this.state;
      const getSigningExperience = signingId
        ? getSigningDocPages(signingId, documentId)
        : createSigningDocs(documentId, documentType);

      cancelWhenUnmounted(
        getSigningExperience.subscribe((signingExp) => {
          const { id, pages, consented_to_electronic_records } = signingExp.esign_docs;
          this.setState({ signingId: id, signingSVGs: pages, consented: consented_to_electronic_records });
          if (signingLocations.length === 0) {
            this.loadSigningLocations(id);
          } else {
            //Remove signing locations on pages that don't exist
            this.deleteSigningLocations(
              signingLocations.filter((signingLocation) => signingLocation.page >= pages.length)
            );
          }
        }, handleError)
      );
    }
  };

  setClientCollaborators = (clientId) => {
    const { context, cancelWhenUnmounted, documentType } = this.props;
    const { signingLocations } = this.state;

    cancelWhenUnmounted(
      getClientCollaborators(clientId).subscribe((response) => {
        const clientCollaborators = response.clients.users
          .filter(
            (user) =>
              user.id !== context.loggedInUser.id &&
              (user.role === "Client" ||
                // Filter out practitioners for documents of type 'file' (eSign)
                (documentType === "file" ? false : user.permissions.letters))
          )
          .reduce((collaborators, user) => {
            collaborators[user.id] = user.name;
            return collaborators;
          }, {});

        this.setState({ clientCollaborators });
        this.populateSignerNames(signingLocations, clientCollaborators);
      }, handleError)
    );
  };

  loadSigningLocations = (signingId) => {
    const { documentId, cancelWhenUnmounted } = this.props;
    const { clientCollaborators } = this.state;

    cancelWhenUnmounted(
      getSigningLocations(documentId, signingId).subscribe(({ signing_requests }) => {
        const signingLocations = signing_requests.signing_locations.map((signingLocation) => {
          return {
            ...signingLocation,
            x: translateXfromPDF(signingLocation.x),
            y: translateYfromPDF(signingLocation.y),
            hasBeenSigned: !!signingLocation.value,
          };
        });
        this.setState({ signingLocations, documentCompleted: signing_requests.is_complete });
        this.populateSignerNames(signingLocations, clientCollaborators);
      }, handleError)
    );
  };

  populateSignerNames = (signingLocations, collaboratorsList) => {
    if (signingLocations.length > 0 && collaboratorsList.length > 0) {
      const clientCollaborators = signingLocations.reduce((collaborators, { signatory_user_id }) => {
        const matches = collaboratorsList.find((collaborator) => collaborator.key === signatory_user_id);
        if (matches) {
          collaborators[signatory_user_id] = matches.value;
        }
        return collaborators;
      }, {});

      this.setState({ clientCollaborators });
    }
  };

  goToSigningLocations = (step) => {
    let locations = cloneDeep(
      this.state.isClient
        ? this.state.signingLocations.filter((loc) => userMatchesSigningLocation(loc, this.state.loggedInUserSignature))
        : this.state.signingLocations
    ).sort((a, b) => a.page - b.page);

    locations[step]?.ref?.current.scrollIntoView({ behavior: "smooth", block: "center" });

    if (locations.length - 1 === step) return 0;

    step++;
    return step;
  };

  determineDocumentLoaded = () => {
    const lastSigningLocation = cloneDeep(
      this.state.isClient
        ? this.state.signingLocations.filter((loc) => userMatchesSigningLocation(loc, this.state.loggedInUserSignature))
        : this.state.signingLocations
    ).sort((a, b) => b.page - a.page);

    this.setState((prevState) => {
      return {
        loadedPages: prevState.loadedPages + 1,
        signingPagesLoaded: lastSigningLocation?.[0]?.page
          ? prevState.loadedPages + 1 > lastSigningLocation[0].page
          : lastSigningLocation.length,
      };
    });
  };

  updateConsent = (consented) => {
    this.setState({ consented });
  };

  render() {
    const { clientId, clientRequestId, closeModal, context, documentId, documentType, resolutionCaseId, title } =
      this.props;

    const {
      activeTab,
      clientRequestData,
      documentCompleted,
      esignContext,
      esignTaskId,
      isClient,
      loggedInUserSignature,
      setEsignContext,
      setSignersContext,
      showClientRequestCreatedSuccessfullyModal,
      showEsignRequestCreatedSuccessfullyModal,
      showSignatureEntryModal,
      signatureEntryModalType,
      signersContext,
      signingId,
      signingLocationsAreDraggable,
      signingRequestIsValid,
      signingLocations,
    } = this.state;
    return (
      <SigningContext.Provider value={{ signersContext, setSignersContext }}>
        <EsignContext.Provider value={{ esignContext, setEsignContext }}>
          <div className={`${styles.modalContent} cps-modal`} style={{ ...(this.phoneOnly && { minWidth: "auto" }) }}>
            <link
              href="https://fonts.googleapis.com/css?family=Allura|Courgette|Mr+Dafoe|Shadows+Into+Light+Two"
              rel="stylesheet"
            />
            <HeaderBar
              clientRequestId={clientRequestId}
              closeCancel={this.closeCancel}
              closeModal={closeModal}
              completeDocumentAsClient={this.completeDocumentAsClient}
              documentCompleted={documentCompleted}
              documentId={documentId}
              documentType={documentType}
              isClient={isClient}
              phoneOnly={this.phoneOnly}
              signingId={signingId}
              signingLocationsAreDraggable={signingLocationsAreDraggable}
              signingRequestIsValid={signingRequestIsValid}
              title={title}
              goToSigningLocations={this.goToSigningLocations}
              signingPagesLoaded={this.state.signingPagesLoaded}
            />
            <div className={`${styles.modalColumns}`}>
              {signingLocationsAreDraggable && (
                <LettersSidePanel
                  activeTab={activeTab}
                  clientId={clientId}
                  clientRequestData={clientRequestData}
                  context={context}
                  resolutionCaseId={resolutionCaseId}
                  finalizeEsignExperience={this.finalizeEsignExperience}
                  finalizeSigningExperience={this.finalizeSigningExperience}
                  requireClientRequest={documentType !== "file"}
                  setActiveTab={this.setActiveTab}
                  setClientRequestData={this.setClientRequestData}
                  showClientRequestCreatedSuccessfullyModal={showClientRequestCreatedSuccessfullyModal}
                  signatureElements={signatureElements}
                  signingId={signingId}
                  signingLocations={signingLocations}
                  signingRequestIsValid={signingRequestIsValid}
                  tabs={tabs}
                  title={title}
                />
              )}
              <div
                id="docScroll"
                className={`${styles.documentPane}`}
                style={{ ...(this.phoneOnly && { minWidth: "auto", paddingBottom: 0, marginTop: 36 }) }}
              >
                {this.renderDocumentContents()}
              </div>
            </div>
            {showSignatureEntryModal && (
              <SignatureEntryModal
                context={context}
                documentId={this.props.documentType === "file" ? this.props.documentId : "0"}
                hasConsented={this.state.consented}
                phoneOnly={this.phoneOnly}
                setSignature={this.setLoggedInUserSignature}
                shouldShowSignatureEntryModal={this.shouldShowSignatureEntryModal}
                signatureData={loggedInUserSignature}
                signingType={signatureEntryModalType}
                updateConsent={this.updateConsent}
              />
            )}
            {showClientRequestCreatedSuccessfullyModal && (
              <ClientRequestSuccessModal
                clientId={clientId}
                closeModal={closeModal}
                resolutionCaseId={resolutionCaseId}
              />
            )}
            {showEsignRequestCreatedSuccessfullyModal && (
              <EsignRequestSuccessModal clientId={clientId} closeModal={closeModal} esignTaskId={esignTaskId} />
            )}
          </div>
        </EsignContext.Provider>
      </SigningContext.Provider>
    );
  }

  renderDocumentContents = () => {
    if (this.state.esignError) {
      return this.renderError();
    }
    if (!this.state.signingSVGs) {
      return this.renderLoading();
    }
    return this.state.signingSVGs.map((signingSVG, page) => (
      <DocumentView
        clientCollaborators={this.state.clientCollaborators}
        clientId={this.props.clientId}
        context={this.props.context}
        isClient={this.state.isClient}
        key={page}
        loggedInUserSignature={this.state.loggedInUserSignature}
        modalMode={true}
        openClientModal={this.openClientModal}
        page={page}
        phoneOnly={this.phoneOnly}
        resetAddedUsers={this.resetAddedUsers}
        selectSignerNewUser={this.state.selectSignerNewUser}
        setDocumentSize={partial(this.setDocumentSizes, page)}
        setSigningLocations={this.setSigningLocations}
        shouldShowSignatureEntryModal={this.shouldShowSignatureEntryModal}
        signingLocations={this.state.signingLocations}
        signingLocationsAreDraggable={this.state.signingLocationsAreDraggable}
        signingSVG={signingSVG}
        determineDocumentLoaded={this.determineDocumentLoaded}
      />
    ));
  };

  renderLoading = () => {
    return (
      <div className={`cps-card cps-depth-2 ${styles.loadingWrapper} ${styles.document}`}>
        <div className={`${styles.loader}`}>
          <CpLoader size="lg" />
          <p className="cps-body cps-cool-gray cps-text-center">
            <span className="cps-wt-bold">We are preparing your document for eSignature.</span>
            <br />
            <span>Please wait or check back later.</span>
          </p>
        </div>
      </div>
    );
  };

  renderError = () => {
    return (
      <div className={`cps-card cps-depth-2 ${styles.loadingWrapper} ${styles.document}`}>
        <div className={`${styles.loader}`}>
          <CpEmptyState
            img="es_caution"
            text="There was a problem preparing this file."
            subText="Please try again later."
          />
        </div>
      </div>
    );
  };

  openClientModal = () => {
    SystemJS.import("clients-ui!sofe").then((module) => {
      module.showInviteClientModal(this.props.clientId, this.collaboratorsUpdated);
    });
  };

  collaboratorsUpdated = (addedUserId) => {
    this.setClientCollaborators(this.props.clientId);
    this.setState({ selectSignerNewUser: addedUserId });
  };

  resetAddedUsers = () => {
    this.setState({ selectSignerNewUser: [] });
  };

  closeCancel = () => {
    const signingLocations = remapSigningLocationsToRatio(this.state.signingLocations, this.state.documentSizes);
    const newState = Object.assign({}, this.state, { signingLocations });
    sessionStorage.setItem(`partialSigningData_${this.props.documentId}`, JSON.stringify(newState));
    this.props.closeModal();
  };

  setClientRequestData = (clientRequestData) => {
    this.setState({ clientRequestData });
  };

  setSigningLocations = (signingLocations, clientNameObj) => {
    const signingState = { signingLocations };
    if (clientNameObj) {
      Object.assign(signingState, { clientCollaborators: { ...this.state.clientCollaborators, ...clientNameObj } });
    }
    this.setState(signingState);
  };

  deleteSigningLocations = (signingLocationsToDelete) => {
    const signingObjects = this.state.signingLocations;
    signingLocationsToDelete.forEach((toDelete) => {
      const index = findIndex(signingObjects, (item) => item.id === toDelete.id);
      signingObjects.splice(index, 1);
    });

    this.setSigningLocations(signingObjects);
  };

  setLoggedInUserSignature = (loggedInUserSignature) => {
    this.setState({ loggedInUserSignature });
    this.state.markFieldAsSigned();
    this.shouldShowSignatureEntryModal(false);
  };

  shouldShowSignatureEntryModal = (
    showSignatureEntryModal = false,
    isForType = signingFieldTypes.SIGNATURE,
    markFieldAsSigned = () => {}
  ) => {
    this.setState({
      showSignatureEntryModal,
      signatureEntryModalType: showSignatureEntryModal ? isForType : null,
      markFieldAsSigned,
    });
  };

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  };

  isLoggedInUser = (id) => {
    return id === this.props.context.loggedInUser.id;
  };

  completeDocumentAsClient = () => {
    const { documentId, clientRequestId, clientId, resolutionCaseId, closeModal, cancelWhenUnmounted, documentType } =
      this.props;
    const { signingId, signingLocations, loggedInUserSignature } = this.state;
    const consentedLocations = signingLocations.map((location) => ({
      ...location,
      consented_to_electronic_records: this.state.consented,
    }));

    if (!this.signingRequestIsValid()) {
      //Invalid, do not send request
      toasts.infoToast("Make sure to complete all the signing fields");
      return;
    }

    signAllUserLocations(documentId, signingId, consentedLocations, loggedInUserSignature);

    if (this.allUsersHaveSigned(signingLocations, false) && documentType === "letter") {
      cancelWhenUnmounted(
        // Mark client request as 'needs_review'
        getClientRequest(clientId, resolutionCaseId, clientRequestId).subscribe((response) => {
          updateClientRequest(clientId, resolutionCaseId, clientRequestId, {
            client_requests: { ...response.client_requests, status: "needs_review" },
          }).subscribe(noop, handleError);

          closeModal("needs_review");
          toasts.successToast("Your signed document was successfully sent");
        }, handleError)
      );
    } else {
      closeModal();
    }
  };

  allUsersHaveSigned = (signingLocations, includeCurrentUser = true) => {
    return (
      signingLocations
        .filter(
          (signingLocation) =>
            includeCurrentUser || signingLocation.signatory_user_id !== this.state.loggedInUserSignature.id
        )
        .filter((signingLocation) => !signingLocation.value).length === 0
    );
  };

  /**
   * Make sure practitioner has signed and that all signature fields on page have been assigned
   */
  signingRequestIsValid = () => {
    const {
      signingLocationsAreDraggable,
      signingLocations,
      loggedInUserSignature,
      signingId,
      signingRequestIsValid,
      documentCompleted,
    } = this.state;
    const { documentType } = this.props;
    let isValid = true;

    if (documentCompleted) return false; // do not enable Send button
    if (!signingLocationsAreDraggable) {
      const currentUserLocations = signingLocations.filter((location) =>
        this.isLoggedInUser(location.signatory_user_id)
      );

      if (currentUserLocations.length) {
        isValid = !currentUserLocations.some(
          (signingObject) => isValid && !(signingObject.hasBeenSigned || signingObject.type === signingFieldTypes.DATE)
        );
      } else {
        isValid = false;
      }
    } else {
      if (
        signingLocations.filter((signingObject) => signingObject.signatory_user_id !== loggedInUserSignature.id)
          .length < 1
      ) {
        //No client signing locations placed
        isValid = false;
      }
      signingLocations.forEach((signingObject) => {
        if (isValid && !signingObject.signatory_user_id) {
          //Need to assign field to a signer
          isValid = false;
        } else if (
          signingObject.signatory_user_id === loggedInUserSignature.id &&
          (!loggedInUserSignature.signatureText || !loggedInUserSignature.initials)
        ) {
          //Practitioner still needs to sign
          isValid = false;
        }
      });
    }
    if (documentType !== "file" && !signingId) {
      isValid = false;
    }

    if (signingRequestIsValid !== isValid) {
      this.setState({ signingRequestIsValid: isValid });
    }
    return isValid;
  };

  finalizeSigningExperience = (clientRequestObj, notifyUsers = true) => {
    const { notificationIds, clientId, resolutionCaseId, documentId, cancelWhenUnmounted } = this.props;
    const { signingId, signingLocations, loggedInUserSignature } = this.state;

    if (!this.signingRequestIsValid()) {
      //Invalid, do not send request
      toasts.infoToast("Make sure to complete all the signing fields before sending");
      return;
    }

    if (this.currentlyFinalizingSigningExperience) {
      //prevent double clicking send now
      return;
    }
    this.currentlyFinalizingSigningExperience = true;

    let clientRequestBody = {
      client_requests: { ...clientRequestObj, pivot_id: signingId },
    };

    if (notifyUsers) {
      clientRequestBody.notifications = {
        users: notificationIds,
      };
    }

    cancelWhenUnmounted(
      createClientRequest(clientId, resolutionCaseId, clientRequestBody).subscribe((clientRequestResponse) => {
        sendClientRequest(clientId, clientRequestResponse.client_requests.id).subscribe(noop, handleError);

        cancelWhenUnmounted(
          createSigningLocations(documentId, signingId, signingLocations).subscribe((signingData) => {
            signAllUserLocations(
              documentId,
              signingId,
              signingData.signing_requests.signing_locations,
              loggedInUserSignature
            );

            sessionStorage.removeItem(`partialSigningData_${documentId}`);

            this.setState({ showClientRequestCreatedSuccessfullyModal: true });
          }, handleError)
        );
      }, handleError)
    );
  };

  finalizeEsignExperience = (esignData) => {
    if (!this.signingRequestIsValid()) {
      //Invalid, do not send request
      toasts.infoToast("Make sure to complete all the signing fields before sending");
      return;
    }

    if (this.currentlyFinalizingSigningExperience) {
      //prevent double clicking send now
      return;
    }
    this.currentlyFinalizingSigningExperience = true;

    const { documentId: file_id, cancelWhenUnmounted } = this.props;
    const { signingLocations, loggedInUserSignature, consented: consented_to_electronic_records } = this.state;
    const signing_locations = remapSigningLocationsToRatio(signingLocations, this.state.documentSizes);

    cancelWhenUnmounted(
      postEsignatureForDoc(
        { ...esignData, file_id, signing_locations, consented_to_electronic_records },
        loggedInUserSignature
      ).subscribe(({ esign_docs_id, task_id }) => {
        sessionStorage.removeItem(`partialSigningData_${file_id}`);
        this.setState({
          showEsignRequestCreatedSuccessfullyModal: true,
          esign_docs_id,
          esignTaskId: task_id,
        });
      }, handleError)
    );
  };
}

const DragDropContext = (LetterSignModal) => (props) =>
  (
    <DndProvider backend={HTML5Backend}>
      <LetterSignModal {...props} />
    </DndProvider>
  );

const DecoratedSigningModal = DragDropContext(LetterSignModal);
export default DecoratedSigningModal;

export function showSigningModal({
  context,
  clientId,
  signingId,
  title,
  clientRequestId,
  modalClosed,
  documentType,
  documentId,
  resolutionCaseId,
  notificationIds,
  esignDocument,
}) {
  SystemJS.import("feature-toggles!sofe")
    .then((ft) => ft.fetchFeatureToggles(...featureToggles))
    .then(() => {
      return new Promise((resolve) => {
        const div = document.createElement("div");
        document.body.appendChild(div);
        const root = createRoot(div);
        root.render(
          <DecoratedSigningModal
            clientId={clientId}
            clientRequestId={clientRequestId}
            closeModal={() => {
              root.unmount();
              div.parentNode.removeChild(div);
              resolve();
              modalClosed();
            }}
            context={context}
            documentId={documentId}
            documentType={documentType}
            resolutionCaseId={resolutionCaseId}
            esignDocument={esignDocument}
            notificationIds={notificationIds}
            signingId={signingId}
            title={title}
          />,
          div
        );
      });
    });
}

const signingModalWithError = ErrorBoundary({ featureName: "signing-ui" })(DecoratedSigningModal);

export const SigningParcel = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: signingModalWithError,
  featureToggles: featureToggles,
});
