import React, { useRef } from "react";
import { useCss, k, a } from "kremling";

export const PlaceholderText = ({
  context,
  dropdownRef,
  fieldHasBeenSigned,
  forCurrentUser,
  handleClick = null,
  isDateObject,
  isSignatureObject,
  isTeamMember,
  isTextField,
  openSignatureEntryModal,
  setFieldIsActive,
  showActiveSignerFieldExtras,
  signingLocationsAreDraggable,
  textFieldValue,
  children,
}) => {
  const scope = useCss(css);
  const focusRef = useRef(null);

  const handleMouseDown = (e) => {
    e.stopPropagation();
    // Check toolbar dropdown state before it can be changed by onClick
    const isDropdownOpen = dropdownRef.current?.isOpen;
    if (isDropdownOpen) {
      setFieldIsActive(true);
    } else {
      setFieldIsActive(false);
    }
  };

  // if signed, just return the children and no placeholder text needed
  if (fieldHasBeenSigned) {
    return children;
  }

  if (isTextField) {
    return (
      <div
        {...scope}
        className={a("custom-resizable-box-placeholder-content cp-caption cp-ellipsis").m(
          "custom-resizable-box-placeholder-content-teamMember",
          isTeamMember
        )}
        style={{
          position: "absolute",
          pointerEvents: "none",
          opacity: textFieldValue ? 0 : 1,
          transition: "opacity 0.2s",
        }}
      >
        {(forCurrentUser || (showActiveSignerFieldExtras && !signingLocationsAreDraggable)) && "Click to add text"}
      </div>
    );
  }

  if (forCurrentUser && !isDateObject && !isTextField && !fieldHasBeenSigned) {
    return (
      <span
        {...scope}
        ref={focusRef}
        className={a("custom-resizable-box-placeholder-content cp-caption cp-ellipsis").m(
          "custom-resizable-box-placeholder-content-teamMember",
          isTeamMember
        )}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            forCurrentUser && !isDateObject && !fieldHasBeenSigned && openSignatureEntryModal();
          }
        }}
      >
        {`Click or enter to add ${isSignatureObject ? "signature" : "initials"}`}
      </span>
    );
  }
};

const css = k`
  .custom-resizable-box-placeholder-content {
    width: 100%;
    height: 100%;
    position: relative;
    color: var(--cp-color-default-badge-bg);
    z-index: 3;
    display: flex;
    flex-direction: column; 
    justify-content: flex-end;
  }

  .custom-resizable-box-placeholder-content-teamMember {
    color: var(--cp-color-pill-team-text) !important;
  }
`;
