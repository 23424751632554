import React, { forwardRef } from "react";

// TODO also remove forwardRef
export const ResizeHandle = forwardRef((props, ref) => {
  const { handleAxis, signingObject, ...restProps } = props;
  const isTeamMember =
    signingObject?.isTeamMember ||
    signingObject?.signatory_user_role === "TeamMember" ||
    signingObject?.role === "TeamMember";

  return (
    <div
      ref={ref}
      {...restProps}
      onMouseDown={(e) => {
        // prevent drag behavior on resize handle
        e.stopPropagation();
        e.preventDefault();

        // now call the original onMouseDown from react-resizable
        props.onMouseDown?.(e);
      }}
      style={{
        position: "absolute",
        width: "8px",
        height: "8px",
        backgroundColor: isTeamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)",
        border: `1px solid ${isTeamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)"}`,
        borderRadius: "50%",
        right: "-4px",
        top: "-4px",
        cursor: "ne-resize",
        zIndex: 1000,
      }}
    />
  );
});
ResizeHandle.displayName = "ResizeHandle";
