import React, { useState, useEffect, useContext } from "react";
import { CpTabs } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error.helper";
import styles from "../esign-modal.styles.css";
import { SignersTab } from "./side-panel-tabs/signers-tab.component";
import { FieldsTab } from "./side-panel-tabs/fields-tab.component";
import { SettingsTab } from "./side-panel-tabs/settings-tab.component";
import { KbaWell } from "../../kba/credits/kba-well.component";
import { SelectTemplateDropdown } from "./side-panel-tabs/select-template-dropdown.component";
import { EsignContext, SigningContext } from "../../signing-context";
import { successToast } from "toast-service!sofe";
import { getEsignTemplates } from "../../signing.resource";
import { templateTypes } from "../../constants";

const tabs = {
  signers: { id: "signers", name: "Signers" },
  fields: { id: "fields", name: "Fields" },
  settings: { id: "settings", name: "Settings" },
};

export const SidePanel = ({
  creditsSubtracted,
  setKbaEnabled,
  kbaEnabled,
  primaryClientClientPortalUsers,
  isTemplateMode,
  isTemplate,
  applyTemplate,
  assignSignerTypes,
  signingLocations,
  shouldShowSignatureEntryModal,
  esignTemplateAccess,
  setSigningLocations,
  addSigningLocations,
  ...props
}) => {
  const [currentTab, setCurrentTab] = useState(tabs.signers.id);
  const [esignTemplates, setEsignTemplates] = useState(null);
  const [hasAssignedAllLocations, setHasAssignedAllLocations] = useState(false);
  const [hasEnoughKbaCredits, setHasEnoughKbaCredits] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { signerTypesContext, hasEsignImprovementsBeta } = useContext(SigningContext);
  const { esignContext } = useContext(EsignContext);
  const isPreset = esignContext?.template_type === templateTypes.preset?.id;

  useEffect(() => {
    if (isTemplate) {
      const hasUnassigned = signerTypesContext.find((type) => !type.assignedTo);
      if (!hasAssignedAllLocations && !hasUnassigned && signingLocations.length > 0) {
        setHasAssignedAllLocations(true);
        successToast("All signing locations have been assigned.");
      }
      if (hasUnassigned) setHasAssignedAllLocations(false);
    }
  }, [isTemplate, signerTypesContext]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'hasAssignedAllLocations' and 'signingLocations.length'

  useEffect(() => {
    if (!esignTemplateAccess) return;
    const sub = getEsignTemplates(searchTerm).subscribe((templates) => {
      setEsignTemplates(templates);
    }, handleError);
    return () => sub.unsubscribe();
  }, [esignTemplateAccess, searchTerm]);

  return (
    <div className={`${styles.lightColumn} cp-flex-column justify-between`}>
      <div className="cp-flex-column justify-start" style={{ height: "calc(100vh - 96px)" }}>
        <div style={{ borderBottom: "1px solid var(--cp-color-app-border)" }}>
          <CpTabs block activeId={currentTab} onChange={setCurrentTab}>
            {Object.values(tabs).map((tab) => (
              <CpTabs.Button key={tab?.id} id={tab.id}>
                {tab?.name}
              </CpTabs.Button>
            ))}
          </CpTabs>
        </div>
        <div className="cp-ph-16 cp-pt-16 grow" style={{ overflowX: "auto" }}>
          {currentTab === tabs.signers.id && (
            <SignersTab
              tab={tabs.signers}
              primaryClientClientPortalUsers={primaryClientClientPortalUsers}
              clientId={props.clientId}
              isTemplateMode={isTemplateMode}
              isTemplate={isTemplate}
              assignSignerTypes={assignSignerTypes}
              signingLocations={signingLocations}
              shouldShowSignatureEntryModal={shouldShowSignatureEntryModal}
              setSigningLocations={setSigningLocations}
              addSigningLocations={addSigningLocations}
            />
          )}
          {currentTab === tabs.fields.id && <FieldsTab isTemplateMode={isTemplateMode} tab={tabs.fields} />}
          {currentTab === tabs.settings.id && (
            <SettingsTab
              isTemplateMode={isTemplateMode}
              tab={tabs.settings}
              kbaEnabled={kbaEnabled}
              signingLocations={signingLocations}
              shouldShowSignatureEntryModal={shouldShowSignatureEntryModal}
              setKbaEnabled={setKbaEnabled}
              creditsSubtracted={creditsSubtracted}
              esignTemplateAccess={esignTemplateAccess}
              setHasEnoughKbaCredits={setHasEnoughKbaCredits}
              hasEnoughKbaCredits={hasEnoughKbaCredits}
              {...props}
            />
          )}
          {currentTab !== tabs.settings.id && !isPreset && (
            <KbaWell
              isTemplateMode={isTemplateMode}
              setKbaEnabled={setKbaEnabled}
              kbaEnabled={kbaEnabled}
              creditsSubtracted={creditsSubtracted}
              setHasEnoughKbaCredits={setHasEnoughKbaCredits}
            />
          )}
        </div>
      </div>
      {!isTemplateMode && esignTemplateAccess && (
        <SelectTemplateDropdown
          applyTemplate={applyTemplate}
          esignTemplates={esignTemplates}
          hasEsignImprovementsBeta={hasEsignImprovementsBeta}
          isTemplate={isTemplate}
          setSearchTerm={setSearchTerm}
        />
      )}
    </div>
  );
};
