import React from "react";
import { CpIcon } from "canopy-styleguide!sofe";

export const FieldTypeButton = ({ fieldType, currentType, changeFieldType }) => (
  <button
    className={`cp-select-list__item ${currentType === fieldType.type ? "cp-select-list__item--selected" : ""}`}
    onClick={() => changeFieldType(fieldType.type)}
  >
    <CpIcon className="cp-select-list__icon-left" name={fieldType.cpIcon} />
    <span>{fieldType.label}</span>
    {currentType === fieldType.type && (
      <CpIcon name="checkmark-small" style={{ marginLeft: "auto" }} fill="var(--cp-color-app-success-text)" />
    )}
  </button>
);
