import React, { useEffect } from "react";
import { CpCheckbox, CpInput } from "canopy-styleguide!sofe";
import styles from "./signature-entry-modal.styles.css";
import { signatureFonts } from "../constants";

export default function SignatureEntryCard({
  signatureText,
  selectedFont = "Courgette",
  disabled,
  hasConsented,
  handleInputChange,
  handleFontChange,
  updateConsent,
}) {
  const maxLength = 35;
  //set font on load to default font
  useEffect(() => {
    if (disabled) return;
    handleFontChange(signatureFonts[0]);
  }, [disabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'handleFontChange'
  return (
    <div className="cp-m-24">
      <link
        href="https://fonts.googleapis.com/css?family=Allura|Courgette|Mr+Dafoe|Shadows+Into+Light+Two"
        rel="stylesheet"
      />
      <CpInput
        autoFocus
        className="cp-mb-16 cp-mt-12"
        disabled={disabled}
        label="Signee name"
        maxLength={maxLength}
        onChange={handleInputChange}
        placeholder="Please enter your first and last name"
        required={true}
        type="text"
        value={signatureText}
      />
      <div className={`${styles.signatureFontContainer}`}>
        {!disabled &&
          signatureFonts.map((font, pos) => {
            return (
              <div
                key={pos}
                onClick={() => handleFontChange(font)}
                className={`${styles.signatureFontElement} ${pos !== 0 ? "cp-ml-16" : null} ${
                  font === selectedFont ? styles.selected : null
                }`}
                style={{ fontFamily: `'${font}', cursive` }}
              >
                Signature
              </div>
            );
          })}
      </div>
      <div className={`${styles.signatureSampleContainer}`}>
        <div className={`${styles.signatureSample}`}>
          <i className={`cps-icon cps-icon-close cps-gray-8 ${styles.signatureSampleIcon}`}></i>
          <span className={`${styles.signatureSampleText}`} style={{ fontFamily: `'${selectedFont}', cursive` }}>
            {signatureText}
          </span>
        </div>
      </div>
      {updateConsent && (
        <>
          {/* <div className="cps-body-sm cps-margin-bottom-8">
                Please read the <a target="_blank" href={`/api/docs/esign-docs/${this.props.documentId}/consent-form`}>Electronic Signature and Consent Disclosure</a>,
              </div> */}
          <CpCheckbox onChange={updateConsent} checked={hasConsented} disabled={disabled}>
            I agree to be legally bound by this document.
          </CpCheckbox>
        </>
      )}
    </div>
  );
}
